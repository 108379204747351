import api from "interceptor/api";
import Pagination from "utils/Pagination";
import { useEffect } from "react";
import { t } from "i18next";
import UserBlockIPAdd from "./UserBlockIPAdd"
import useFormData from "common/useFormData";
import { useCountPerPage } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import { NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { Column, Row } from "style/custom/useCustomLayout";
import { MainBtn, RedBtn } from "style/useButtonStyles";
import { BlockBtn } from "components/common/TableStatus";
import { PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { getFormatDatetime } from "utils/date/DateUtil";

export default function UserBlockIP({userNo}) {
  const useTG = useTableData(true);
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();

  const BlockAdd = () => {
    openPopup({
      title: t("user.ip_add"),
      content: <UserBlockIPAdd userNo={userNo} />,
      onClose: getBlockIP,
    });
  };

  const showMessageAlert = (message) => {
    openAlert({
      title: t("user.reasons_registration"),
      message: message || "-",
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };
  
  const showErrorMessageAlert = () => {
    openAlert({
      title: t("sendMessage.delete_fail"),
      message: t("userInfo.delete_msg"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const getBlockIP = () => {
    setLoading(true);
    const apiUrl = `/v1/user/all/block-ip/${userNo}`;
    api
    .get(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
      },
    })
    .then((res) => {
      useTG.setData(res.data.content);
      if (res.data.pageHelperDto) {
        useTG.setPagingInfo(res.data.pageHelperDto);
      }
      setLoading(false);
      useStated.setSuccessData([res.data.content]);
    })
    .catch((err) => {
      console.error(err);
    });
  };

  const deleteBlockIP = () => {
    // ipList 생성
    const ipList = useTG.data.filter((item) => item.isChecked).map((item) => item.no);
    // URLSearchParams 객체 생성
    const params = new URLSearchParams();
    ipList.forEach((gameNo) => params.append("ip_list", gameNo));
    api.delete(`/v1/user/all/block-ip/${userNo}?${params.toString()}`)
    .then(() => {
      getBlockIP();
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        showErrorMessageAlert();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const useSearch = useFormData(
    {},
    getBlockIP,
    useTG
  );

  useEffect(() => {
    useSearch.setFormValue({});
  }, []);

  return (
    <Column className="area-box" $gap="15px">
      <Row $jus="space-between" $width="100%" $align="center" $pad="0 0 14px">
        <RedBtn onClick={deleteBlockIP}>{t("user.ip_del")}</RedBtn>
        <Row $gap="10px" $align="center">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={BlockAdd}>
              {t("user.ip_add")}
            </MainBtn>
          </Row>
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>
      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          <Table className="table-box">
            <thead>
              <tr className="table-title">
                <TableHeader $width="34px" rowspan="2">
                  <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                </TableHeader>
                <TableHeader $width="60px">{t("common.no")}</TableHeader>
                <TableHeader $width="300px">IP</TableHeader>
                <TableHeader>{t("banner.create_at")}</TableHeader>
                <TableHeader>{t("menuCategory.management")}</TableHeader>
              </tr>
            </thead>
            <tbody>
              {useTG.data &&
                useTG.data.map((item, index) => {
                  const number = useTG.pagingInfo.offset - index;
                  return (
                    <TableRow key={item.no} className="table-content">
                      <TableDeps>
                        <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                      </TableDeps>
                      <TableDeps $width="60px">{number || "-"}</TableDeps>
                      <TableDeps $tAlign="left">{item.no}</TableDeps>
                      <TableDeps $tAlign="center">{getFormatDatetime(item.create_at)}</TableDeps>
                      <TableDeps>
                        <Row $width="100%" $jus="center">
                          <BlockBtn name={t("inout.reason")} eventProps={() => showMessageAlert(item.block_reason)} />
                        </Row>
                      </TableDeps>
                    </TableRow>
                  );
                })}
            </tbody>
          </Table>
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}
      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%" $position="relative">
        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row $jus="space-between" $width="100%" $align="center" $pad="0 0 14px">
          <RedBtn onClick={deleteBlockIP}>{t("user.ip_del")}</RedBtn>
          <Row $gap="10px" $align="center">
            <Row $gap="8px">
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={BlockAdd}>
                {t("user.ip_add")}
              </MainBtn>
            </Row>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Row>
    </Column>
  );
}