import useFormData from "common/useFormData";
import { TextareaForm } from "components/common/CommonForm";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

import api from "interceptor/api";
import { useAlert, usePopup } from "contexts/useWindow";
import useStateData from "common/useStateData";
import { t } from "i18next";

export default function UserBlockIPAdd({userNo}) {
  const useStated = useStateData();
  const { closePopup } = usePopup();
  const { openAlert } = useAlert();

  const useForm = useFormData({
    ip_list: "",
    block_reason: ""
  });

  const showErrorMessageAlert = () => {
    openAlert({
      title: t("sendMessage.save_fail"),
      message: t("admin.ip_save_error"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
    };
  
  const InsertBlockIP = () => {
    api.post(`/v1/user/all/block-ip/${userNo}`, {
      ...useForm.formValue
    }).then(() => {
      closePopup();
    }).catch((err) => {
      if (err.response?.status === 400) {
        useStated.setErrorData(err.response.data.errors, true);
      }
      if (err.response?.status === 500) {
        showErrorMessageAlert()
      }
    })
  }

  return (
    <>
      <Column className="area-box" $gap="20px">
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("admin.regis_ip")} 
          </TextBox>
          <Column $width="100%" $gap="4px">
            <TextareaForm
              type="text"
              name="ip_list"
              placeholder={t("admin.block_ip_plh")}
              {...useForm}
              {...useStated}
            />
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              {t("admin.regis_msg")}
            </TextBox>
          </Column>
        </FormBox>
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("inout.reason")} 
          </TextBox>
          <Column $width="100%" $gap="4px">
            <TextareaForm
              type="text"
              name="block_reason"
              placeholder={t("point.reason_input")}
              {...useForm}
              {...useStated}
            />
          </Column>
        </FormBox>
        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={InsertBlockIP}>
              {t("common.save")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
}