import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "interceptor/api";
import styled from "styled-components";
import useStyle from "style/useStyle";

const WriterTitle = styled.input`
  background-color: #191a1e;

  border: 1px solid #e5e5e5 !important;
  border-radius: 12px !important;

  color: var(--c-white);

  width: 100%;
  max-width: 360px;
  padding: 10px;
`;

const WriterContent = styled.textarea`
  box-shadow: var(--shadow);
  border: 1px solid #e5e5e5 !important;
  border-radius: 12px !important;

  color: var(--c-white);

  width: 100%;
  max-width: 100%;
  height: 300px;
  padding: 10px;
  resize: none;
`;

const WriteLayOut = styled.div`
  padding: 20px;
`;

const WriteBtn = styled.button`
  color: #2e81ff;
  background-color: var(--c-white);
  border-radius: 4px;

  padding: 4px 12px;
`;

const NoticeModify = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { FlexBox, TextBox, ContentArea } = useStyle();

  const [modifiedTitle, setModifiedTitle] = useState("");
  const [modifiedContent, setModifiedContent] = useState("");

  useEffect(() => {
    const getNoticeApi = api.get(`/v1/api/notice/?noticeNo=${id}`);
    getNoticeApi.then((response) => {
      const noticeData = response.data; // 예시: 서버 응답에 따라 수정 필요
      setModifiedTitle(noticeData.title);
      setModifiedContent(noticeData.contents);
    });
    getNoticeApi.catch((error) => {
      alert("공지사항 작성에 실패하였습니다. error : ", error);
      console.error(error);
    });
  }, [id]);

  const submitModifyNotice = () => {
    if (modifiedTitle.length < 5 || modifiedContent.length < 5) {
      alert("제목 또는 내용은 5글자 이상 입력해야합니다.");
      return;
    }
    const modifyNoticeApi = api.put(`/v1/api/notice?noticeNo=${id}`, {
      title: modifiedTitle,
      contents: modifiedContent,
    });
    modifyNoticeApi.then((response) => {
      navigate("/notice/list");
    });
    modifyNoticeApi.catch((error) => {
      alert("공지사항 수정에 실패하였습니다. error : ", error);
      console.error(error);
    });
  };

  return (
    <ContentArea>
      <>
        <WriteLayOut>
          <FlexBox
            $jus="space-between"
            $align="center"
          >
            <TextBox
              as="p"
              $size="24px"
            >
              공지사항 수정 번호 : {id}
            </TextBox>
            <TextBox
              as="button"
              onClick={() => navigate("/notice/list")}
              $size="16px"
            >
              이전 페이지
            </TextBox>
          </FlexBox>
          <FlexBox
            $pad="20px 0"
            $gap="12px"
            $direct="column"
          >
            <TextBox>제목</TextBox>
            <WriterTitle
              type="text"
              value={modifiedTitle}
              onChange={(e) => setModifiedTitle(e.target.value)}
            />
          </FlexBox>
          <FlexBox
            $pad="20px 0"
            $jus="flex-start"
            $gap="12px"
            $direct="column"
          >
            <TextBox>내용</TextBox>
            <WriterContent
              value={modifiedContent}
              placeholder="내용을 입력해주세요."
              onChange={(e) => setModifiedContent(e.target.value)}
            />
          </FlexBox>
          <FlexBox $jus="flex-end">
            <WriteBtn onClick={submitModifyNotice}>수정하기</WriteBtn>
          </FlexBox>
        </WriteLayOut>
      </>
    </ContentArea>
  );
};

export default NoticeModify;
