import React from "react";
import Routers from "routes/Routers";
import { RecoilRoot } from "recoil";

import { CommonWindowProvider } from "contexts/useWindow";
import { CommonStateProvider } from "contexts/useCommonState";

import GlobalStyle from "style/GlobalStyle";
import SiteNameSetter from "components/common/SiteNameSetter";
import AlarmConfigSetter from "components/common/AlarmConfigSetter";

const SiteInfo = ({ children }) => {
  const info = localStorage.getItem("auth-admin");
  console.log(info);
  return (
    <>
      {!info && <>
        <SiteNameSetter />
        <AlarmConfigSetter />
      </>
      }
      {children}
    </>
  );
};

function App() {

  return (
    <>
      <GlobalStyle />
      <CommonStateProvider>
        <CommonWindowProvider>
          <RecoilRoot>
            <SiteInfo>
              <Routers />
            </SiteInfo>
          </RecoilRoot>
        </CommonWindowProvider>
      </CommonStateProvider>
    </>
  );
}

export default App;
