import api from "interceptor/api";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import { FilterTitle } from "style/custom/useCustomText";
import CalendarFilter from "components/common/CalendarFilter";
import UserNameTab from "components/common/UserNameTab";
import Pagination from "utils/Pagination";
import { t } from "i18next";
import useFormData from "common/useFormData";
import { useCountPerPage } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import { InputForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { Column, Row, FilterBox } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { TextBox } from "style/useTextStyles";
import { getFormatDateMin } from "utils/date/DateUtil";

export default function AgentCommissionHistory({agentNo}) {
  const useTG = useTableData(true);
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const getAgentCommission = () => {
    setLoading(true);
    const apiUrl = `/v1/agent/commission/history/${agentNo}`;
    api.get(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
      },
    }).then((res) => {
      useTG.setData(res.data.content);
      if (res.data.pageHelperDto) {
        useTG.setPagingInfo(res.data.pageHelperDto);
      }
      useStated.setSuccessData([res.data.content]);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const useSearch = useFormData(
    {
      search_agent_category: "",
      search_agent_val: "",
      search_sub_agent_yn: "Y",
      search_agent_name: "",
      search_agent_type: "",
      search_transaction_id: "",
      search_user_nickname: "",
      start_date: "",
      end_date: ""
    },
    getAgentCommission,
    useTG
  );

  return (
    <Column $width="1600px">
      <FilterBox $pad="12px">
        <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
          <AgentSearchFilter useSearch={useSearch} useStated={useStated} />
          <Column $gap="4px">
            <FilterTitle>{t("user.user")}</FilterTitle>
            <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
          </Column>
          <Column $gap="4px">
            <FilterTitle>{t("betting.no")}</FilterTitle>
            <InputForm type="text" name="search_transaction_id" placeholder={t("common.input")} {...useSearch} {...useStated}/>
          </Column>
          <Column $gap="4px">
            <FilterTitle>{t("betting.win_time")}</FilterTitle>
            <Row $gap="4px">
              <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
            </Row>
          </Column>
        </Row>
        <Row $gap="8px">
          <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
            {t("filter.search")}
          </MainBtn>
          <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
            {t("filter.refresh")}
          </WhiteBtn>
        </Row>
      </FilterBox>
      <Row className="total-count" $gap="10px" $pad="10px 0" $jus="space-between">
        <Row $gap="10px">
          <TextBox $font="var(--f-subB)">{t("gameManagement.total_agent_commission")}</TextBox>
          <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
            {useTG.error ? (
              <span>error</span>
            ) : (
              <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
            )}
          </TextBox>
          <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
        </Row>
        <SelectForm
          {...useTG}
          name="countPerPage"
          placeholder={useCountPerPage[0].label}
          size={"var(--s-table)"}
          line={"var(--l-table)"}
          options={useCountPerPage}
          formValue={useTG.extendData}
        />
      </Row>
      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          <Table className="table-box">
            <thead>
              <tr className="table-title">
                <TableHeader $minWidth="50px" $width="60px" rowspan="2">
                  <TextBox>{t("common.no")}</TextBox>
                </TableHeader>
                <TableHeader width="100px">{t("betting.no")}</TableHeader>
                <TableHeader width="100px">{t("agent.code")}</TableHeader>
                <TableHeader width="100px">{t("agent.agent")}</TableHeader>
                <TableHeader width="100px">{t("user.user_id")}</TableHeader>
                <TableHeader width="100px">{t("betting.bet_amount")}</TableHeader>
                <TableHeader width="100px">{t("betting.win_amount")}</TableHeader>
                <TableHeader width="100px">{t("betting.win_time")}</TableHeader>
                <TableHeader width="180px">{t("agent.commission_rolling_rate")}</TableHeader>
                <TableHeader width="180px">{t("agent.game_commission_rolling_rate")}</TableHeader>
                <TableHeader width="180px">{t("agent.commission_original_rolling_amount")}</TableHeader>
                <TableHeader width="180px">{t("agent.game_commission_rolling_amount")}</TableHeader>
                <TableHeader width="180px">{t("agent.commission_rolling_amount")}</TableHeader>
                <TableHeader width="180px">{t("agent.commission_losing_rate")}</TableHeader>
                <TableHeader width="180px">{t("agent.game_commission_losing_rate")}</TableHeader>
                <TableHeader width="180px">{t("agent.commission_original_losing_amount")}</TableHeader>
                <TableHeader width="180px">{t("agent.game_commission_losing_amount")}</TableHeader>
                <TableHeader width="180px">{t("agent.commission_losing_amount")}</TableHeader>
                <TableHeader $width="80px">{t("agent.sum_rolling_losing_amount")}</TableHeader>
              </tr>
            </thead>
            {useTG && useTG.data?.length > 0 && (
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const number = useTG.pagingInfo?.offset - index;
                    return (
                      <TableRow key={number} className="table-content">
                        <TableDeps $width="60px">{number || "-"}</TableDeps>
                        <TableDeps $tAlign="left">{item.transaction_id}</TableDeps>
                        <TableDeps $tAlign="left">{item.code}</TableDeps>
                        <TableDeps $tAlign="left"><AgentNameTab no={item.agent_no} code={item.code} name={item.name} type_name={item.type_name} /></TableDeps>
                        <TableDeps><UserNameTab no={item.user_no} nickname={item.user_name} /></TableDeps>
                        <TableDeps $tAlign="right">{parseInt(item.bet_amount).toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{parseInt(item.win_amount).toLocaleString()}</TableDeps>
                        <TableDeps>{getFormatDateMin(item.process_at)}</TableDeps>
                        <TableDeps $tAlign="right">{`${parseFloat(item.rolling_rate).toFixed(2)}%`}</TableDeps>
                        <TableDeps $tAlign="right">{`${parseFloat(item.game_rolling_rate).toFixed(2)}%`}</TableDeps>
                        <TableDeps $tAlign="right">{item.original_rolling_amount.toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{item.game_rolling_amount.toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{item.rolling_amount.toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{`${parseFloat(item.losing_rate).toFixed(2)}%`}</TableDeps>
                        <TableDeps $tAlign="right">{`${parseFloat(item.game_losing_rate).toFixed(2)}%`}</TableDeps>
                        <TableDeps $tAlign="right">{item.original_losing_amount.toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{item.game_losing_amount.toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{item.losing_amount.toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="right">{item.sum_amount.toLocaleString()}</TableDeps>
                      </TableRow>
                    );
                  })}
              </tbody>
            )}
          </Table>
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}
      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row />
        <Row $align="center" $gap="4px">
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>
    </Column>
  );
}