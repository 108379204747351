import React, { useRef } from "react";

// 공용
import api from "interceptor/api";

import useTableData from "common/useTableData";

import { useCommonState } from "contexts/useCommonState";
// style import
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { SelectForm } from "components/common/CommonForm";
import useFormData from "common/useFormData";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import { usePopup } from "contexts/useWindow";
import { useCountPerPage } from "constants/select/useValue";
import NoDataTable from "components/common/NoDataTable";
import { t } from "i18next";
import useStateData from "common/useStateData";
import { BlockBtn } from "components/common/TableStatus";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import AgentNameTab from "components/common/AgentNameTab";

const AgentInfoList = ({ setAgent }) => {
  const { setLoading } = useCommonState();
  const useStated = useStateData();

  const { closePopup } = usePopup();
  const popupRef = useRef();

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = "/v1/agent/info";
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_agent_category: "",
      search_agent_val: "",
      search_sub_agent_yn: "Y",
      search_agent_name: "",
      search_agent_type: "",
    },
    getTableItem,
    useTG
  );

  const clickSelect = (agent) => {
    setAgent(agent);
    closePopup();
  };

  return (
    <>
      <Column $width="900px" ref={popupRef}>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} />
          </Row>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-count" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("agent.code")}</TableHeader>
                  <TableHeader>{t("agent.agent")}</TableHeader>
                  <TableHeader $width="80px">{t("agent.join_code")}</TableHeader>
                  <TableHeader width="100px">{t("agent.sub_agent_count")}</TableHeader>
                  <TableHeader $width="60px">{t("common.select")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => (
                    <TableRow key={item.no} className="table-content">
                      <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>
                      <TableDeps $tAlign="left">
                        <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                          {item.code || "-"}
                        </TextBox>
                      </TableDeps>
                      <TableDeps $tAlign="left">
                        <AgentNameTab
                          no={item.no}
                          code={item.code}
                          name={item.name}
                          type_name={item.type_name}
                          popupRef={popupRef}
                        />
                      </TableDeps>
                      <TableDeps>{item.join_code}</TableDeps>
                      <TableDeps $tAlign="right">{(item?.children_agent_count || 0).toLocaleString()}</TableDeps>

                      <TableDeps>
                        <BlockBtn name={"선택하기"} eventProps={() => clickSelect(item)} />
                      </TableDeps>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
              top="top"
            />
          </Row>
        </Row>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentInfoList;
