import axios from "axios";

const checkSessionApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true
});

checkSessionApi.interceptors.request.use((config) => {
  const authAdmin = localStorage.getItem('auth-admin');
  if (authAdmin) config.headers['Authorization'] = `Bearer ${authAdmin}`;

  return config;
});

export default checkSessionApi;
